import React, { FC } from 'react';
import { Button, PageHeader, Layout } from 'antd';
import './App.css';
const {
  Content,
  Footer
} = Layout

function App() {
  return (
    <div className="App" style={{height: '100%',}}>
      <Layout style={{height: '100%',}}>
        <PageHeader title={"云故的存储空间"} className={"site-page-header"} />
        <Content className={'site-layout-content'}>
          <h1>感谢您来访云故的存储空间！</h1>
          <p>这个存储空间主要是存储云故的一些大容量文件！</p>
          <p>所以这里就不会有内容啦，烦请您<a href={"https://zeithrold.com"}>回到主站</a>继续浏览！</p>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          2021 云故的存储空间 <a href={'https://beian.miit.gov.cn'}>辽ICP备2021000038号</a>
        </Footer>
      </Layout>


    </div>
  );
}

export default App;
